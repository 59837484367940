import React from 'react'

// CSS
import { P, H2, BlueStripe, SingleGradient, StyledBackgroundImage } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'

const Wrapper = styled.div`
  min-height: 526px;

  ${H2} {
    width: 40%;
  }

  @media (max-width: 992px) {
    ${H2} {
      width: 100%;
    }
  }
`

const FooterBannerImage = ({ onlyImage, bannerImage, className }) => (
  <Wrapper className={`${className ? `${className}` : ``}`}>
    {onlyImage ? (
      <>
        <StyledBackgroundImage className="h-100 position-absolute col-12" fluid={bannerImage} alt="" />
        <SingleGradient className="h-100 position-absolute col-12" />
      </>
    ) : (
      <>
        <StyledBackgroundImage className="h-100 position-absolute col-12" fluid={bannerImage.image.localFile.childImageSharp.fluid} alt="" />
        <SingleGradient className="h-100 position-absolute col-12" />
        <div className="container py-5 px-5 px-lg-0">
          <BlueStripe width={52} className="color-background-main mt-5 mb-2" />
          <H2 className="font-size-xl px-0 px-lg-0 pb-3 font-weight-xl color-text-light">{bannerImage.title}</H2>
          <P className="font-size-xm font-weight-xl color-main">{bannerImage.sub_title}</P>
        </div>
      </>
    )}
  </Wrapper>
)

export default FooterBannerImage
