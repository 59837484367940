import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import theme from 'styles/theme'
import { H2, Image, P, BlueStripe } from 'components/StyledComponents'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import GravityForm from 'components/GravityForm'
import TitleBannerImage from 'components/TitleBannerImage'
import FooterBannerImage from 'components/FooterBannerImage'

// Third Party
import styled from 'styled-components'

const ContactItem = styled.div`
  ${H2} {
    opacity: 0.15;
    font-size: 45px;
  }

  ${Image} {
    padding: 0;
  }

  .col-7 {
    padding-right: 0;
  }

  b {
    font-size: 14px;
    font-weight: 400;
  }
`

const FormWrapper = styled.div`
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.13);
  border-radius: 3px;
`

const ButtonDefault = styled.div`
  background-color: ${theme.colorContrast};
  padding: 10px 70px 10px;
  border-radius: 3px;
  transition: 0.25s;

  :hover {
    transform: scale(1.05);
  }

  ${P} {
    color: ${theme.colorTextLight};
    font-weight: ${theme.fontWeightXl};
  }
`

const Contact = ({ pageContext }) => {
  const {
    contact: {
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage
            }
          }
        },
        contact_banner_image_2: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage2
            }
          }
        },
        contact,
        address,
        availability,
        email,
        route,
        h1_tag: h1Tag
      },
      yoast_meta: yoast
    }
  } = useStaticQuery(graphql`{
    contact: wordpressPage(wordpress_id: {eq: 17}) {
      acf {
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contact_banner_image_2 {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        availability {
          mon_to_fri
          sat
          title
          icon {
            localFile {
              publicURL
            }
          }
        }
        address {
          title
          zipcode_city
          address
          icon {
            localFile {
              publicURL
            }
          }
        }
        contact {
          common_phonenumber
          glasgarage_phonenumber
          rush_phonenumber
          icon {
            localFile {
              publicURL
            }
          }
        }
        email {
          email
          email_2
          icon {
            localFile {
              publicURL
            }
          }
        }
        route {
          streetname
          housenumber
          zipcode
          city
          button_text
        }
        h1_tag
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }`)

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <h1 className="d-none">{h1Tag}</h1>
      <TitleBannerImage image={bannerImage} pageContext={pageContext} breadCrumbTitle="Contact" title="Neem contact op" className="row position-relative d-flex align-items-end" />
      <div className="container">
        <div className="row py-5">
          <ContactItem className="col-12 col-lg-4 pb-5 pb-lg-0">
            <H2 className="font-weight-xl pb-3">Contact</H2>
            <div className="d-flex">
              <Image className="col align-self-start" src={contact.icon.localFile.publicURL} alt="" />
              <div className="col-4">
                <P className="font-weight-xl font-size-mms pb-2">Algemeen</P>
                <P className="font-weight-xl font-size-mms pb-2">Mobiel</P>
                <P className="font-weight-xl font-size-mms">Spoed</P>
              </div>
              <div className="col-7">
                <P className="font-size-mms pb-2"><a href={`tel:${contact.common_phonenumber}`}>{contact.common_phonenumber}</a></P>
                <P className="font-size-mms pb-2"><a href={`tel:${contact.glasgarage_phonenumber}`}>{contact.glasgarage_phonenumber}</a></P>
                <P className="font-size-mms pb-2">
                  <a href={`tel:${contact.rush_phonenumber}`}>{contact.rush_phonenumber}</a>
                  <b> (24/7 bereikbaar)</b>
                </P>
              </div>
            </div>
            <div className="d-flex">
              <Image className="col align-self-start" src={email.icon.localFile.publicURL} alt="" />
              <div className="col-11">
                <a href={`mailto:${email.email}`} className="font-weight-xl color-text-contrast font-size-mms pb-2">{email.email}</a>
                <br />
                <a href={`mailto:${email.email_2}`} className=" font-weight-xl color-text-contrast font-size-mms pb-2">{email.email_2}</a>
              </div>
            </div>
          </ContactItem>
          <ContactItem className="col-12 col-lg-4 pb-5 pb-lg-0">
            <H2 className="font-weight-xl pb-3">Adres</H2>
            <div className="d-flex">
              <Image className="col align-self-start" src={address.icon.localFile.publicURL} alt="" />
              <div className="col-11">
                <P className="font-weight-xl font-size-mms pb-2">{address.title}</P>
                <P className="font-size-mms pb-2">{address.address}</P>
                <P className="font-size-mms">{address.zipcode_city}</P>
              </div>
            </div>
          </ContactItem>
          <ContactItem className="col-12 col-lg-4">
            <H2 className="font-weight-xl pb-3">Bereikbaarheid</H2>
            <div className="d-flex">
              <Image className="col align-self-start" src={availability.icon.localFile.publicURL} alt="" />
              <div className="col-11">
                <P className="font-weight-xl font-size-mms pb-2">{availability.title}</P>
                <P className="font-size-mms pb-2">{`Ma - Vrij ${availability.mon_to_fri}`}</P>
                <P className="font-size-mms">{`Zaterdag ${availability.sat}`}</P>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-3">
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${route.streetname}+${route.housenumber},+${route.zipcode}+${route.city}&travelmode=driving`}
                rel="noopener noreferrer"
                target="_blank"
                className=""
              >
                <ButtonDefault>
                  <P>{route.button_text}</P>
                </ButtonDefault>
              </a>

            </div>
          </ContactItem>
        </div>

        
      </div>
      <div className="empty-space-300 pt-4 container position-relative d-flex justify-content-center justify-content-lg-start mb-5 mb-lg-0">
        <FormWrapper className="position-absolute color-background-light p-4 col-10 col-lg-5">
          <BlueStripe className="color-background-main mb-3" />
          <H2 className="font-weight-xl font-size-xl pb-3">Neem contact met ons op!</H2>
          <GravityForm contact id={1} />
        </FormWrapper>
      </div>

      <FooterBannerImage onlyImage bannerImage={bannerImage2} className="row position-relative" />
    </Layout>
  )
}

export default Contact